

import UserProfile from '../components/user-profile.vue';
import Signup from '../signup/signup.vue';

export default {
    name: 'pl_cleaner',
    components: {
        'userProfile': UserProfile,
        'signUp': Signup
    },
    created() {

        let t = this;

        document.body.classList.add('pc');

        t.loadData();

    },
    activated() {
        let t = this;
    },
    methods: {

        loadData() {

            let t = this;

            if (t.$route.name == 'pl_employee') {
                t.roles = [1, 4];
            }
            else {
                t.roles = [2, 3];
            }

            axios.get('employee?role=' + t.roles.join(',')).then(res => {
                if (res.data.status == 1) {
                    let arr = res.data.options.items;
                    arr.forEach(item => {
                        item.showPopover = false;
                    })
                    t.list.employee = arr;
                }
            });

        },

        viewEmployeeAttendace() {
            this.showAttendance = true;
            this.loadMonthlyStats();
        },

        loadMonthlyStats() {

            let t = this;

            let dayIndexs = ['日', '月', '火', '水', '木', '金', '土'];

            t.dictWaitingTask = {};

            t.list.dateGrid.clear();

            axios.get('group').then(res => {
                if (res.data.status == 1) {

                    let arr = res.data.options.items;

                    let dictRoomGroup = {};

                    arr.forEach(group => {

                        let roomIds = group.room_ids = group.room_ids.split(',');

                        roomIds.forEach(roomId => {
                            dictRoomGroup[roomId] = group;
                        });
                    });

                    axios.post('cleanTask/readMonthly/' + t.year + '/' + t.month + "?eid=" + t.employee.id + '&_with=waiting').then(res => {
                        if (res.data.status == 1) {

                            let dictDate = {};

                            let todayText = (new Date()).format('MM/dd');
                            let todayVal = new Date();

                            let startDate = new Date(res.data.options.start_date);
                            let endDate = new Date(res.data.options.end_date);

                            let i = 0;

                            let days = new Date(startDate).getDay();

                            t.list.placeholder = [];

                            for (let j = 0; j < days; j++) {
                                t.list.placeholder.push('');
                            }

                            while (true) {
                                let curDate = new Date(res.data.options.start_date);
                                curDate.setDate(curDate.getDate() + i);
                                if (curDate > endDate)
                                    break;
                                let date = curDate.format('MM/dd');
                                let dayIndex = dayIndexs[curDate.getDay()];
                                let item = {
                                    date: date,
                                    day_index: dayIndex,
                                    week_index: curDate.getDay(),
                                    tasks: [],
                                    is_today: date == todayText,
                                    is_past: curDate < todayVal
                                };
                                dictDate[date] = item;
                                t.list.dateGrid.push(item);
                                i++;
                            }

                            let arr = res.data.options.items;

                            arr.forEach(task => {
                                let date = new Date(task.date).format('MM/dd');
                                let item = dictDate[date];
                                if (task.cleaner_id == t.employee.id) {
                                    item.tasks.push(task);
                                }
                                else {
                                    if (!t.dictWaitingTask[date])
                                        t.dictWaitingTask[date] = [];

                                    t.dictWaitingTask[date].push(task);
                                }
                            });

                            let groupIds = localStorage['selected_group_ids'];

                            if (groupIds) {
                                groupIds = groupIds.split(',');
                            }


                            t.list.dateGrid.forEach(item => {

                                let groups = [];

                                item.tasks.forEach(task => {
                                    let roomId = task.room_id;
                                    let group = dictRoomGroup[roomId];
                                    if (groups.indexOf(group) < 0)
                                        groups.push(group);
                                });

                                let dictGroup = listToDict(groups);

                                if (groups.length > 0 && groupIds.length > 0) {

                                    let notIn = [];

                                    groups.forEach(group => {
                                        if (groupIds.indexOf(group.id.toString()) < 0) {
                                            notIn.push(group.id);
                                        };
                                    })

                                    let arr2 = [];

                                    groupIds.forEach(groupId => {
                                        if (dictGroup[groupId])
                                            arr2.push(dictGroup[groupId]);
                                    });

                                    notIn.forEach(groupId => {
                                        if (dictGroup[groupId])
                                            arr2.push(dictGroup[groupId]);
                                    });

                                    groups = arr2;

                                }

                                item.tasks.forEach(task => {
                                    let roomId = task.room_id;
                                    let group = dictRoomGroup[roomId];
                                    let i1 = groups.indexOf(group) + 1 * 1000;
                                    let i2 = group.room_ids.indexOf(roomId.toString()) + 1;
                                    task.index = i1 + i2;
                                });

                                item.tasks = item.tasks.sort((a, b) => {
                                    return a.index - b.index;
                                });

                            });

                        }
                    });



                }
            });


        },

        minusMonth() {
            let t = this;
            let r = addMonthsToDate(new Date(t.year, t.month - 1, 1), -1);
            t.month = r.getMonth() + 1;
            t.year = r.getFullYear();
            t.loadMonthlyStats();
        },

        plusMonth() {
            let t = this;
            let r = addMonthsToDate(new Date(t.year, t.month - 1, 1), 1);
            t.month = r.getMonth() + 1;
            t.year = r.getFullYear();
            t.loadMonthlyStats();
        },

        editDateGrid(item) {

            if (item.is_past)
                return;

            let t = this;

            t.currentItem = item;
            t.selectedTask = null;
            t.showCleanTaskEditor = true;
            t.list.task = t.dictWaitingTask[item.date];
        },

        removeItem(item) {

            let t = this;

            if (t.busying)
                return;

            t.busying = true;

            A.safety('Are you sure to delete this clean task?').then(res => {

                t.busying = false;

                if (res.isConfirmed) {

                    let f = {
                        id: item.order_id,
                        cleaner_id: null,
                    };

                    axios.post('order/setCleaner', f).then(res => {

                        t.busying = false;

                        if (res.data.status == 1) {

                            t.currentItem.tasks.remove(item);
                            t.$forceUpdate();
                        }
                    });

                }
            });
        },

        addTask() {
            let t = this;

            if (t.busying)
                return;

            t.busying = true;

            let item = t.selectedTask;

            t.currentItem.tasks.push(t.selectedTask);

            t.dictWaitingTask[t.currentItem.date].remove(t.selectedTask);

            t.selectedTask = null;

            let f = {
                id: item.order_id,
                cleaner_id: t.employee.id,
                clean_status: 1,
            };

            axios.post('order/setCleaner', f).then(res => {

                t.busying = false;

                if (res.data.status == 1) {
                    item.cleaner_id = t.employee.id;
                    item.status = 1;
                    t.$forceUpdate();
                }
            });

        },

        afterDeleteProfile() {

            let t = this;

            t.showUserProfile = false;
            t.list.employee.remove(t.employee);
            t.employee = null;

        },

        onSelect(action, index) {

            if (index == 0) {
                this.viewEmployeeProfile();
            }
            else if (index == 1) {
                this.viewEmployeeAttendace();
            }

        },

        viewEmployeeProfile() {
            this.showUserProfile = true;
        },

        afterSignUp() {
            this.showSignUp = false;
            this.loadData();
        },

        signUp() {
            this.showSignUp = true;
        }

    },
    data() {
        return {
            showSignUp: false,
            showUserProfile: false,
            dictWaitingTask: {},
            employee: null,
            showAttendance: false,
            //attendace
            attendanceExpanded: true,
            year: (new Date()).getFullYear(),
            month: (new Date()).getMonth() + 1,
            list: {
                dateGrid: [],
                employee: [],
                task: [],
                placeholder: []
            },
            selectedTask: null,
            currentItem: null,
            showTaskPopover: false,
            showCleanTaskEditor: false,

            actions: [
                { text: '情報', },
                { text: '清掃実績' },
            ],

            roles: []
        };
    },
    computed: {
    }
};
