

import ordersBox from './orders-box.vue';

export default {
  name: "StatsHeader",
  components: {
    ordersBox
  },
  props: {
  },
  created() {

    if (window._startRealtimeStr != 1)
      window._startRealtimeStr = 1;
    setInterval(function () {
      if (document.getElementById('realtimeStr'))
        document.getElementById('realtimeStr').innerText = (new Date()).format('hh:mm:ss');
      if (document.querySelector(".realtimeStr_1"))
        document.querySelector(".realtimeStr_1").innerText = new Date().format(
          "hh:mm:ss"
        );
      if (document.querySelector(".realtimeStr_2"))
        document.querySelector(".realtimeStr_2").innerText = new Date().format(
          "hh:mm:ss"
        );
    }, 1000);
    let t = this;
    function refreshStats() {
      axios.get('order/stats4Dashboard').then(res => {
        if (res.data.status == 1) {
          t.stats = res.data.options.stats;
        }
      });
    }

    refreshStats();

    setInterval(function () {
      refreshStats();
    }, 1000 * 10);
  },
  mounted() { },
  activated() {
    
  },
  deactivated() {
  },
  methods: {
  },
  data() {
    return {
      showOrdersBox: false,
      stats: {
        unhandle_order: 0,
        unfill_form: 0,
        today_unclean: 0,
        today_emergency_appointment: 0,
      },
    };
  },
};
