

export default {
    name: "SearchPicker",
    props: {
        items: {
            type: Array,
            required: true
        },
        onselect: {
            type: Function,
            required: false
        },
        textField: {
            type: String,
            required: false,
            default: 'name'
        },
        valueField: {
            type: String,
            required: false,
            default: 'id'
        },
        value: {
            type: Number,
            default: null
        },
        autoHide: {
            type: Boolean,
            required: false,
            default: false
        },
        toggle: {
            type: Boolean,
            required: false,
        },
    },
    mounted() { },
    created() {
        let t = this;
        t.items.forEach(item => {
            item._active = false;
            if(t.value != -1){
                if(item[t.valueField] == t.value){
                    t.selection = item;
                    item._active = true;
                }
            }
        });
    },
    activated: function (el) {
    },
    deactivated() {
    },
    methods: {
        selectItem(item) {

            if(this.autoHide){
                this.$emit('update:toggle', false);
            }
            
            if(this.selection == item)
                return;

            if(this.selection){
                this.selection._active = false;
            }

            this.selection = item;

            item._active = !item._active;

            this.$forceUpdate();

            if (this.onselect)
                this.onselect(item);
            
            this.$emit('input', item[this.valueField]);
        }
    },
    data() {
        return {
            keywords: '',
            selection: null,
        };
    },
    computed: {
        filteredItems() {
            let t = this;
            return t.items.filter((item) => {
                if (item[t.textField] != undefined) {
                    if (item[t.textField].indexOf(t.keywords) > -1) return true;
                }
                return false;
            });
        },
    }
};
