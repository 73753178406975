

export default {
    name: "Checkboxes",
    props: {
        items: {
            type: Array,
            required: true
        },
        selection: {
            type: Array,
            required: false,
            default: () => {
                return [];
            }
        },
        onselect: {
            type: Function,
            required: true
        },
        title: {
            type: String,
            required: false
        },
        value: {
            type: Boolean,
            required: false
        },
        textField: {
            type: String,
            required: false,
            default: 'name'
        },
    },
    mounted() { },
    created() {
        let t = this;

        t.items.forEach(item => {
            t.$set(item,'_active',false);
            if (t.selection.indexOf(item) > -1) {
                item._active = true;
            }
        });

    },
    activated: function (el) {
    },
    deactivated() {
    },
    methods: {
        confirm() {
            let t = this;

            let arr = [];

            t.items.forEach(item => {
                if (item._active)
                    arr.push(item);
            });


            t.show = false;
            t.onselect(arr);
        },
        close(){
            this.$emit('input', false);
        }
    },
    data() {
        return {
            keywords: '',
            show: true
        };
    },
    computed: {
        filteredItems() {
            let t = this;
            return t.items.filter((item) => {
                if (item[t.textField] != undefined) {
                    if (item[t.textField].indexOf(t.keywords) > -1) return true;
                }
                return false;
            });
        },
    }
};
