

import VueDragResize from 'vue-drag-resize'

export default {
  name: "OrdersBox",
  components: {
    VueDragResize
  },
  props: {
    value: {
      type: Boolean,
      required: false
    },
  },
  mounted() {
    let t = this;
    t.initUI();
  },
  created() {
    let t = this;

    let rect = localStorage['rect_orders_box'] || '{}';
    rect = JSON.parse(rect);
    t.rect.x = rect.x || 150;
    t.rect.y = rect.y || 80;
    t.rect.scale = rect.scale || 100;

    t.loadStats();
    t.loadData();
  },
  activated: function (el) {
  },
  deactivated() {
  },
  methods: {

    loadStats() {
      let t = this;

      axios.get('orderLog/stats').then(res => {
        if (res.data.status == 1) {
          t.stats = res.data.options.stats;
        }
      });

      axios.get('room?sample=name').then(res => {
        if (res.data.status == 1) {
          let arr = res.data.options.items;
          t.list.room = arr;
          t.dict.room = listToDict(t.list.room);
        }
      });

    },

    loadData() {
      let t = this;

      let p = {
        '_with': 'tasks,order,confirmor',
        'status': t.type == 4 ? 1 : 0
      };

      axios.get('orderLog', { params: p }).then(res => {
        if (res.data.status == 1) {
          let arr = res.data.options.items;
          arr.forEach(item => {
            item.selected = false;
            if (item.before_data)
              item.before_data = JSON.parse(item.before_data);
          });
          t.list.log = arr;
        }
      });

    },
    initUI() {

    },
    confirm() {

      let t = this;

      let ids = [];
      let items = [];

      t.list.log.forEach(item => {
        if (item.selected) {
          ids.push(item.id);
          items.push(item);
        }
      });

      if (ids.length == 0) {
        A.err('少なくとも1つの予約を確認する。');
        return;
      }

      axios.post('orderLog/batchConfirm', { ids: ids.join(',') }).then(res => {
        if (res.data.status == 1) {
          items.forEach(item => {
            t.list.log.remove(item);
          });
          t.loadStats();
        }
      });

    },
    close() {
      this.show = false;
      this.$emit('input', false);
    },

    onDragStop(o) {
      this.rect.x = o.left;
      this.rect.y = o.top;
      localStorage['rect_orders_box'] = JSON.stringify(this.rect);
    },

    onScaleChange() {
      localStorage['rect_orders_box'] = JSON.stringify(this.rect);
    }
  },
  data() {
    return {
      rect: {
        x: 0,
        y: 0,
        scale: 100
      },
      isDraggable: false,
      show: true,
      type: 2,
      stats: {
        new: 0,
        cancel: 0,
        update: 0,
        history: 0,
      },
      list: {
        log: [],
        room: []
      },
      dict: {
        room: {}
      }
    };
  },
  computed: {
    filteredLogs() {
      let t = this;
      return t.list.log.filter((item) => {
        if (item.type == t.type || t.type == 4) {
          return true;
        }
        return false;
      });
    },
  }
};
