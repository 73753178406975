
import Checkboxes from '../components/checkboxes.vue';
import SearchPicker from '../components/search-picker.vue';
import SortPicker from '../components/sort-picker.vue';

export default {
    name: 'cc',
    components: {
        'sort-picker': SortPicker,
        'search-picker': SearchPicker,
        'checkboxes': Checkboxes
    },
    created() {

        let t = this;

        document.body.classList.add('pc');

        t.month = (new Date()).getMonth() + 1;

        t.today = new Date((new Date()).format('yyyy/MM/dd'));

        axios.get('employee?role=2,3').then(res => {
            if (res.data.status == 1) {
                t.list.employee = res.data.options.items;
                t.dict.employee = listToDict(t.list.employee);
                t.loadData();
            }
        });

        t.list.group = [{
            name: 'グループA',
            bath: 0,
            toilet: 0,
            king: 0,
            queen: 0,
            double: 0,
            single: 0,
            employee: {
                id: 1,
                firstname: '',
                lastname: '',
            }
        }];
    },
    activated() {

        let t = this;

    },
    methods: {

        loadData() {

            let t = this;

            //读取今日清扫房间

            let p = {
                '_with': 'room,order',
                'date': t.today.format('yyyy-MM-dd')
            };


            axios.get('cleanTask', { params: p }).then(res => {
                if (res.data.status == 1) {

                    let arr = res.data.options.items;

                    t.list.room.clear();

                    arr.forEach(item => {
                        if (item.room) {
                            item.room.task = item;
                            if (item.group_id) {

                            }
                            else {
                                t.list.room.push(item.room);
                            }

                        }
                    });

                    t.dict.room = listToDict(t.list.room);
                }
            });

        },

        selectRooms(rooms) {
            console.log(rooms);
        },

        addGroup() {

            this.list.group.push({
                name: 'グループ',
                bath: 0,
                toilet: 0,
                king: 0,
                queen: 0,
                double: 0,
                single: 0,
                employee_id: null
            });
        },

        setGroup(item) {

            let t = this;

            t.group = item;

            t.showRoomsPopup = true;

        }

    },
    data() {
        return {
            minDate: new Date('2024/01/01'),
            currentDate: Date.today(),
            today: Date.today(),
            list: {
                employee: [],
                room: [],
                selectedRoom: [],
                cleanTask: [],
                group: []
            },
            dict: {
                employee: {},
                room: {},
                cleanTask: {}
            },
            stats: {

            },
            showEmployeePopover: false,
            showRoomsPopup: false,
            group: {}
        };
    },
    computed: {
    }
};
